import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './page/home/Home';
import Login from '../src/page/login/Login'
import History from './page/history/History';
import Blog from './page/blog/Blog'
import Dashboard from './page/dashbord/Dashboard'
const App = () => {
  return (
    <div className="app">
       <BrowserRouter>
      <Routes>
      <Route path="/" element={<Login />} />  
        <Route path="/Home" element={<Home />} />  
      <Route path="/History" element={<History />} />  
       <Route path="/Blog" element={< Blog/>} />  
     <Route path="/Dashboard" element={<Dashboard />} />  
      {/*  <Route path="/DetailDoc" element={<DetailDoc />} /> 
        <Route path="/Catagory" element={<Catagory />} />  
        {/* Add other routes here */}
      </Routes>
    </BrowserRouter>
     
    </div>
  )
}

export default App