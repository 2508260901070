import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import './topbar.scss';
import { Settings } from "@material-ui/icons";
import firebase from '../../firebaseConfige'; // Import your Firebase configuration

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        // Check if there's a user signed in
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            // Fetch user profile data from Firestore
            const userProfileRef = firebase.firestore().collection('admin-user').doc(user.uid);
            const userProfileSnapshot = await userProfileRef.get();
  
            if (userProfileSnapshot.exists) {
              setUserProfile(userProfileSnapshot.data());
            } else {
              console.log('No user profile found.');
            }
          } else {
            console.log('No user signed in.');
            
          }
        });
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };
    fetchUserProfile();
  }, []);
  
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className={isScrolled ? "navbar scrolled" : "navbar"}>
      <div className="container">
        <div className="topbarWrapper">
          <div className="topLeft">
            <span className="logo">User panel</span>
          </div>
          <div className="center">
            <div className="links">
            <span onClick={() => handleNavigation("/Dashboard")}>Dashboard</span>
              <span onClick={() => handleNavigation("/Home")}>upload</span>
              <span onClick={() => handleNavigation("/Blog")}>Blog</span>
              <span onClick={() => handleNavigation("/History")}>History</span>
            </div>
          </div>
          <div className="right">
            {userProfile && (
              <div className="user-profile">
                {userProfile.profilePic && (
                  <img src={userProfile.profilePic} alt="Profile" className="profile-picture" />
                )}
                <span className="username">{userProfile.name}</span>
              </div>
            )}
          </div>
          <div className="topbarIconContainer">
            <Settings style={{ color: "white" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
