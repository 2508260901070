import React, { useState, useEffect } from 'react';
import './history.scss';
import Navbar from '../../component/topbar/Topbar';
import firebase from '../../firebaseConfige'; // Ensure the correct path to your firebase.js file

const History = () => {
  const [videos, setVideos] = useState([]);
  const [userName, setUserName] = useState('');
  const [userCategory, setUserCategory] = useState('');

  useEffect(() => {
    fetchUserData();
    fetchUserVideos();
  }, []);

  const fetchUserData = async () => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const userId = currentUser.uid;
        const userRef = firebase.firestore().collection('users').doc(userId);
        const userDoc = await userRef.get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          setUserName(userData.Name);
          setUserCategory(userData.category);
        }
      } else {
        console.log('No user signed in.');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchUserVideos = async () => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        const userId = currentUser.uid;
        const userVideosRef = firebase.firestore().collection('videos').doc(userId).collection('user-videos');
        const snapshot = await userVideosRef.get();
        const userVideos = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setVideos(userVideos);
      } else {
        console.log('No user signed in.');
      }
    } catch (error) {
      console.error('Error fetching user videos:', error);
    }
  };

  const renderVideos = () => {
    return videos.map((video) => (
      <div key={video.id} className="videoContainer">
        <video controls>
          <source src={video.videoURL} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="videoInfo">
          <p>Uploader: {userName}</p>
          <p>Title: {video.title}</p>
          <p>Description: {video.description}</p>
          <p>Category: {userCategory}</p>
        </div>
        {/* You can add approve/decline buttons if needed */}
      </div>
    ));
  };

  return (
    <div className="lateVid">
      <Navbar />
      <div className="lateVidContent">
        <h2>Late Video Upload</h2>
        {renderVideos()}
      </div>
    </div>
  );
};

export default History;
