import React, { useEffect, useState, useRef } from 'react';
import Chart from 'chart.js/auto';
import './dashboard.scss'; // Import the SCSS file
import Navbar from '../../component/topbar/Topbar';

const Dashboard = () => {
  const [data, setData] = useState({
    totalUploads: 0,
    downloads: 0,
    avgUploadSize: 0,
    totalDownloads: 0,
    totalViews: 0,
    totalWatchHours: 0,
  });
  const chartRef = useRef(null); // Reference to the line chart instance
  const pieChartRef = useRef(null); // Reference to the pie chart instance

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy(); // Destroy previous line chart instance
    }
    if (pieChartRef.current) {
      pieChartRef.current.destroy(); // Destroy previous pie chart instance
    }
    updateChart();
    updatePieChart();
  }, [data]); // Re-run effect when data changes

  const fetchData = () => {
    // Simulated data, replace with actual API calls
    setData({
      totalUploads: 100,
      downloads: 80,
      avgUploadSize: 25,
      totalDownloads: 200,
      totalViews: 500,
      totalWatchHours: 250,
    });
  };

  const updateChart = () => {
    const ctx = document.getElementById('analyticsChart').getContext('2d');

    chartRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['Total Uploads', 'Downloads', 'Avg Upload Size', 'Total Downloads'],
        datasets: [
          {
            label: 'Count',
            data: [
              data.totalUploads,
              data.downloads,
              data.avgUploadSize,
              data.totalDownloads,
            ],
            backgroundColor: [
              'rgba(54, 162, 235, 0.5)',
              'rgba(75, 192, 192, 0.5)',
              'rgba(255, 205, 86, 0.5)',
              'rgba(153, 102, 255, 0.5)',
            ],
            borderColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(255, 205, 86, 1)',
              'rgba(153, 102, 255, 1)',
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  };

  const updatePieChart = () => {
    const pieCtx = document.getElementById('analyticsPieChart').getContext('2d');

    pieChartRef.current = new Chart(pieCtx, {
      type: 'pie',
      data: {
        labels: ['Total Views', 'Total Watch Hours'],
        datasets: [
          {
            label: 'Count',
            data: [data.totalViews, data.totalWatchHours],
            backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)'],
            borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
            borderWidth: 1,
            
          },
        ],
      },
    });
  };

  return (
    <div className="dashboard">
      <Navbar />
      <h1>Dashboard</h1>

      <div className="stats-container">
        <div className="stat-box">
          <h3>Total Uploads</h3>
          <p>{data.totalUploads}</p>
        </div>
        <div className="stat-box">
          <h3>Downloads</h3>
          <p>{data.downloads}</p>
        </div>
        <div className="stat-box">
          <h3>Avg Upload Size</h3>
          <p>{data.avgUploadSize} MB</p>
        </div>
        <div className="stat-box">
          <h3>Total Downloads</h3>
          <p>{data.totalDownloads}</p>
        </div>
      </div>
      <div className="analytics-container">
        <div className="chart-container">
          <h2>Analytics</h2>
          <div className="charts-wrapper">
            <canvas id="analyticsChart"></canvas>
            <canvas id="analyticsPieChart"></canvas>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;