// firebaseConfig.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyA9GqIJMNvIbO1sgfAHvi-yPB6O1AQzfp4",
  authDomain: "doctor-712d3.firebaseapp.com",
  projectId: "doctor-712d3",
  storageBucket: "doctor-712d3.appspot.com",
  messagingSenderId: "186187991766",
  appId: "1:186187991766:web:6a08e69e6147acafedd777",
  measurementId: "G-HQYWDD4F5M"
};

firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
export default firebase;
